import React, { useState, useEffect } from "react";
import { Table, Space, Button, message, Card } from "antd";
import { useDispatch } from "react-redux";
import {
  approveInstructorUpdation,
  declineInstructorUpdation,
  getUpdationInstructor,
} from "../../actions/adminInstructor/adminInstructor";

const UpdationRequest = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [instructor, setInstructor] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          limit: pageSize,
        };
        const result = await dispatch(getUpdationInstructor(params));
        setInstructor(result.data);
        setTotalPage(result.totalPage);
      } catch (error) {
        console.error("Error fetching categories:", error);
        message.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch,pageSize,currentPage]);

  const handleApprove = async (id) => {
    try {
      const res = await dispatch(approveInstructorUpdation(id));
      if (res.success) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleDecline = async (id) => {
    try {
      const res = await dispatch(declineInstructorUpdation(id));
      if (res.success) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred. Please try again later.");
      }
    }
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "NYCCertificateNumber",
      dataIndex: "NYCCertificateNumber",
      key: "NYCCertificateNumber",
    },
    {
      title: "Trainer As",
      dataIndex: "trainerAs",
      key: "trainerAs",
    },
    {
      title: "Status",
      dataIndex: "approvedByAdmin",
      key: "approvedByAdmin",
      render: (approvedByAdmin) => (
          <div
            style={{
              padding:"5px",
              borderRadius:"5px",
              textAlign:'center',
              color: approvedByAdmin ? 'green' : 'red',
              backgroundColor: approvedByAdmin ? 'lightgreen' : 'lightcoral',
            }}
          >
            {approvedByAdmin ? 'Approved' : 'Pending'}
          </div>
        )
        
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="default" onClick={() => handleDecline(record.id)}>
            Decline
          </Button>
          <Button type="primary" onClick={() => handleApprove(record.id)}>
            Approve
          </Button>
        </Space>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <Card>
      <div style={{ overflowX: "auto" }}>
        <Table
          dataSource={instructor}
          columns={columns}
          loading={loading}
          pagination={{
            pageSize: pageSize,
            current: currentPage,
            onChange: handlePageChange,
            total: totalPage * pageSize,
          }}
        />
      </div>
    </Card>
  );
};

export default UpdationRequest;
