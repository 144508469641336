import React from "react";
import { Layout, Menu, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/image/yoga.jpg";
import {
  faSeedling,
  faFolder,
  faFile,
  faEye,
  faStar,
  faTachometerAlt,
  faUsers,
  faQuestionCircle,
  faSignOutAlt,
  faGraduationCap,
  faChalkboardTeacher,
  faCalendarAlt,
  faFileImport
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCategory from "../category/addCategory/AddCategory";
import AddSubCategory from "../subCategory/addSubCategory/AddSubCategory";
import AddAasana from "../aasana/addAasana/AddAasana";
import AddCelebrity from "../celebrity/addCelebrity/AddCelebrity";
import ViewCategory from "../category/viewCategory/ViewCategory";
import ViewSubCategory from "../subCategory/viewSubCategory/ViewSubCategory";
import ViewAasana from "../aasana/viewAasana/ViewAasana";
import Users from "../users/Users";
import InstructorManagement from "../adminInstructor/InstructorManagement";
import InstituteManagement from "../adminInstitute/InstituteManagement";
import AdminEvent from "../adminEvent/AdminEvent";
import QuizManagement from "../adminQuiz/QuizManagement";
import { LOGOUT_ADMIN } from "../../constants/actionTypes";
import { useDispatch } from "react-redux";
import Celebrity from "../celebrity/Celebrity";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;



const customMenuStyle = {
  height: "100vh",
};

const customMenuItemStyle = {
  marginTop: "10px",
  fontSize: "16px",
  fontWeight: 600,
};


const MyLayout = () => {
  const location = useLocation();
  const dispatch=useDispatch();
  const navigate=useNavigate();

  const handleLogout = () => {
    dispatch({ type: LOGOUT_ADMIN });
    message.success("Admin logout successfully!");
    navigate("/");
  };
  

  const menuSliderItems = [
    {
      key: "1",
      label: "Dashboard",
      icon: <FontAwesomeIcon icon={faTachometerAlt} />,
      link: "/admin/dashboard",
    },
  
    {
      key: "2",
      label: "Aasanas",
      icon: <FontAwesomeIcon icon={faSeedling} />,
      subMenu: [
        {
          key: "2.1",
          label: "Category",
          icon: <FontAwesomeIcon icon={faFolder} />,
          subMenu: [
            {
              key: "2.1.1",
              label: "View Category",
              link: "/admin/category/view",
              icon: <FontAwesomeIcon icon={faEye} />,
            },
            {
              key: "2.1.2",
              label: "Add New Category",
              link: "/admin/category/add",
              icon: <FontAwesomeIcon icon={faFolder} />,
            },
          ],
        },
        {
          key: "2.2",
          label: "Sub Category",
          icon: <FontAwesomeIcon icon={faFile} />,
          subMenu: [
            {
              key: "2.2.1",
              label: "View Sub Category",
              link: "/admin/sub-category/view",
              icon: <FontAwesomeIcon icon={faEye} />,
            },
            {
              key: "2.2.2",
              label: "Add New Sub Category",
              link: "/admin/sub-category/add",
              icon: <FontAwesomeIcon icon={faFile} />,
            },
          ],
        },
        {
          key: "2.3",
          label: "Aasana",
          icon: <FontAwesomeIcon icon={faSeedling} />,
          subMenu: [
            {
              key: "2.3.1",
              label: "View Aasana",
              link: "/admin/aasana/view",
              icon: <FontAwesomeIcon icon={faEye} />,
            },
            {
              key: "2.3.2",
              label: "Add New Aasana",
              link: "/admin/aasana/add",
              icon: <FontAwesomeIcon icon={faSeedling} />,
            },
          ],
        },
        {
          key: "2.4",
          label: "Import",
          link: "/admin/import",
          icon: <FontAwesomeIcon icon={faFileImport} />,
        },
      ],
    },
    {
      key: "3",
      label: "Celebrity Testimonial",
      icon: <FontAwesomeIcon icon={faStar} />,
      subMenu: [
        {
          key: "3.1",
          label: "View Celebrity",
          link: "/admin/celebrity/view",
          icon: <FontAwesomeIcon icon={faEye} />,
        },
        {
          key: "3.2",
          label: "Add New Celebrity",
          link: "/admin/celebrity/add",
          icon: <FontAwesomeIcon icon={faStar} />,
        },
      ],
    },
    {
      key: "4",
      label: "Users",
      icon: <FontAwesomeIcon icon={faUsers} />,
      link: "/admin/users",
    },
    {
      key: "5",
      label: "Instructor",
      icon: <FontAwesomeIcon icon={faChalkboardTeacher} />,
      link: "/admin/instructor",
    },
    {
      key: "6",
      label: "Institute",
      icon: <FontAwesomeIcon icon={faGraduationCap} /> ,
      link: "/admin/institute",
    },
  
    {
      key: "7",
      label: "Event",
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      link: "/admin/event",
    },
    {
      key: "8",
      label: "Quiz",
      icon: <FontAwesomeIcon icon={faQuestionCircle} />,
      link: "/admin/quiz",
    },
    {
      key: "9",
      label: "Logout",
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      onClick: handleLogout,
    },
  ];



  const renderSubMenu = (subMenuItems) => (
    <SubMenu
      key={subMenuItems.key}
      title={subMenuItems.label}
      icon={subMenuItems.icon}
      style={customMenuItemStyle}
    >
      {subMenuItems.subMenu.map((subItem) =>
        subItem.subMenu ? (
          renderSubMenu(subItem)
        ) : (
          <Menu.Item
            key={subItem.key}
            icon={subItem.icon}
            style={customMenuItemStyle}
          >
            <Link to={subItem.link}>{subItem.label}</Link>
          </Menu.Item>
        )
      )}
    </SubMenu>
  );
  return (
    <Layout hasSider>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        width={260}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "64px",
            background: "#4CBB17",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            className="logo-image"
            style={{ height: "60px", borderRadius: "50%" }}
          />
          <h1
            style={{
              color: "#fff",
              marginLeft: "10px",
              fontSize: "18px",
            }}
          >
            Namaste Yoga
          </h1>
        </div>{" "}
        <Menu mode="inline" defaultSelectedKeys={["1"]} style={customMenuStyle}>
          {menuSliderItems.map((item) =>
            item.subMenu ? (
              renderSubMenu(item)
            ) : (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                style={customMenuItemStyle}
                onClick={item.key === "9" ? item.onClick : null}
              >
                <Link to={item.link} style={{ textDecoration: "none" }}>
                  {item.label}
                </Link>
              </Menu.Item>
            )
          )}

          <div className="demo-logo-vertical" />
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: "#DFFF00",
          }}
        />
        <Content
          style={{
            // margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
        
          >
            {location.pathname === "/admin/category/add" && <AddCategory />}
            {location.pathname === "/admin/category/view" && <ViewCategory />}
            {location.pathname === "/admin/sub-category/add" && (
              <AddSubCategory />
            )}
            {location.pathname === "/admin/sub-category/view" && (
              <ViewSubCategory />
            )}
            {location.pathname === "/admin/aasana/add" && <AddAasana />}
            {location.pathname === "/admin/celebrity/add" && <AddCelebrity />}
            {location.pathname === "/admin/celebrity/view" && <Celebrity />}
            {location.pathname === "/admin/aasana/view" && <ViewAasana />}
            {location.pathname === "/admin/users" && <Users />}
            {location.pathname === "/admin/instructor" && (
              <InstructorManagement />
            )}
            {location.pathname === "/admin/institute" && (
              <InstituteManagement />
            )}
            {location.pathname === "/admin/event" && <AdminEvent />}
            {location.pathname === "/admin/quiz" && <QuizManagement />}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Namaste Yoga
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MyLayout;
