import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  celebrity: [],
  state: "idle",
  error: null,
  success: null,
};

export const addCelebrityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CELEBRITY:
      return {
        ...state,
        celebrity: action.payload.celebrity,
      };
    case actionTypes.GET_CELEBRITY:
      return {
        ...state,
        celebrity: action.payload,
      };
    case actionTypes.UPDATE_CELEBRITY:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case actionTypes.DELETE_CELEBRITY:
      const celebrityIdToDelete = action.payload;
      console.log(action.payload);
      return {
        ...state,
        celebrity: state.celebrity.data.filter(
          (celebrity) => celebrity.id !== celebrityIdToDelete
        ),
      };

    default:
      return state;
  }
};

export default addCelebrityReducer;
