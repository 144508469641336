import * as api from "../../api";
import {
 ADD_CELEBRITY,
 DELETE_CELEBRITY,
 GET_CELEBRITY,
 UPDATE_CELEBRITY
} from "../../constants/actionTypes";

export const addCelebrity = (userInfo) => async (dispatch) => {
    try {
      const response = await api.addCelebrity(userInfo);
      dispatch({ type: ADD_CELEBRITY, payload: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  export const getCelebrity = (params) => async (dispatch) => {
    try {
      const { data } = await api.getCelebrity(params);
      dispatch({ type: GET_CELEBRITY, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  export const updateCelebrity = (userInfo) => async (dispatch) => {
    try {
      const response = await api.updateCelebrity(userInfo);
      dispatch({ type: UPDATE_CELEBRITY, payload: response.data });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  export const deleteCelebrity = (id) => async (dispatch) => {
    try {
      const response = await api.deleteCelebrity(id);
      dispatch({ type: DELETE_CELEBRITY, payload: id });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };