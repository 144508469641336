import React, { useState, useEffect } from "react";
import { Table, Breadcrumb,Card } from "antd";
import {HomeOutlined } from "@ant-design/icons";
import { getUser } from "../../actions/users/users";
import { useDispatch } from "react-redux";

const Users = () => {
  const dispatch=useDispatch();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage,setTotalPage]=useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          limit: pageSize,
        };
        const result = await dispatch(getUser(params));
        setUsers(result.data);
        setTotalPage(result.totalPage);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch,currentPage,pageSize]);



  const columns = [
    {
      title: "SNo",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
   
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };


  return (
    <div style={{ padding: "20px" }}>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "5px",
      }}
    >
      <p style={{ fontSize: "22px" }}>Users</p>
      <Breadcrumb style={{ margin: "22px 0" }}>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/admin/dashboard">
            <HomeOutlined />
          </a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <Card >
        <div style={{ overflowX: "auto" }}>
      <Table dataSource={users} columns={columns} loading={loading}
        pagination={{
              pageSize: pageSize,
                current: currentPage,
                onChange: handlePageChange,
                total: totalPage * pageSize
                // pageSizeOptions: ["10", "20", "30", "40","50","60","70","80","90","100","200","500","1000"],
                // showSizeChanger: true,
              }} />
      </div>
      </Card>
    </div>
  );
};

export default Users;
