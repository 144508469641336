import { SEND_OTP,VERIFY_OTP,GENERATE_PASSWORD } from "../../constants/actionTypes";
import * as api from "../../api";


export const sendOtp = (otpInfo) => async (dispatch) => {
    try {
        const { data } = await api.sendOtp(otpInfo);
        dispatch({ type: SEND_OTP, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const verifyOtp = (otpInfo) => async (dispatch) => {
    try {
        const { data } = await api.verifyOtp(otpInfo);
        dispatch({ type: VERIFY_OTP, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const generatePassword = (userInfo) => async (dispatch) => {
    try {
        const { data } = await api.generatePassword(userInfo);
        dispatch({ type: GENERATE_PASSWORD, payload: data });
        return data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
