import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Breadcrumb,
  Card,
  Popconfirm,
  Button,
  message,
  Modal,
  Form,
  Input,
} from "antd";
import { HomeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  deleteCelebrity,
  getCelebrity,
  updateCelebrity,
} from "../../actions/addCelebrity/addCelebrity";

const Celebrity = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState("");
  const [id, setId] = useState("");

  const columns = [
    {
      title: "SNo",
      dataIndex: "index",
      key: "index",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Celebrity Name",
      dataIndex: "celebrityName",
      key: "celebrityName",
    },

    {
      title: "Video Path",
      dataIndex: "videoPath",
      key: "videoPath",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Watch Video
        </a>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedRecord(record);
              setId(record.id);
              setIsModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this aasana?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          limit: pageSize,
        };
        const result = await dispatch(getCelebrity(params));
        setData(result.data);
        setTotalPage(result.totalPage)
      } catch (error) {
        console.error("Error fetching categories:", error);
        message.error(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch,pageSize,currentPage]);

  const onFinish = async (values) => {
    try {
      setEditLoading(true);
      const data = {
        id,
        ...values,
      };
      const response = await dispatch(updateCelebrity(data));
      if (response.success) {
        message.success(response.message);
        form.resetFields();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error.response.data.message);
    } finally {
      setEditLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log(id);
      const res = await dispatch(deleteCelebrity(id));
      if (res.success) {
        message.success(res.message);
      } else {
        message.error("Failed to delete Aasana.");
      }
    } catch (error) {
      console.error("Error deleting Aasana:", error);
      message.error(error.response.data.message);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px",
        }}
      >
        <p style={{ fontSize: "22px" }}>View Celebrity</p>
        <Breadcrumb style={{ margin: "22px 0" }}>
          <Breadcrumb.Item>View Celebrity</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/admin/dashboard">
              <HomeOutlined />
            </a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card>
        <div style={{ overflowX: "auto" }}>
          <Table dataSource={data} columns={columns} loading={loading}
             pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
              total: totalPage * pageSize,
            
            }} />
        </div>
      </Card>
      <Modal
        title="Edit Celebrity"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {selectedRecord && (
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              celebrityName: selectedRecord.celebrityName,
              videoPath: selectedRecord.videoPath,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Celebrity Name"
              name="celebrityName"
              rules={[
                { required: true, message: "Please input the celebrity name!" },
              ]}
            >
              <Input placeholder="Celebrity Name" />
            </Form.Item>
            <Form.Item
              label="Video Path"
              name="videoPath"
              rules={[
                { required: true, message: "Please input the video path!" },
              ]}
            >
              <Input placeholder="Video Path" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={editLoading}>
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default Celebrity;
