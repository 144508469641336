import React, { useState } from "react";
import { Card, Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import OtpInput from "./OtpInput";
import {
  sendOtp,
  verifyOtp,
} from "../../actions/forgetPassword/forgetPassword";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);

  const onFinish = async (values) => {
    try {
      console.log("Received values:", values);
      setLoading(true);
      const res = await dispatch(sendOtp(values));

      if (res.success) {
        message.success(res.message);
        setEmail(res.data.email);
        form.resetFields();
        setShowOtpInput(true);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("An error occurred during sign-in:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const onOtpSubmit = async (otp) => {
    try {
      if (!email) {
        return;
      }
      setLoading(true);
      const data = {
        email,
        otp,
      };
      const res = await dispatch(verifyOtp(data));

      if (res.success) {
        message.success(res.message);
        navigate("/generate-password", { state: { email: res.data.email } });
      } else {
        message.error(res.message);
      }
    } catch (error) {
      console.error("An error occurred during verify-otp:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      {!showOtpInput ? (
        <Card title="Forget Password" style={{ width: 400 }}>
          <Form
            name="login-form"
            onFinish={onFinish}
            form={form}
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                {
                  validator: (_, value) => {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                    if (!emailRegex.test(value)) {
                      return Promise.reject(
                        "Please enter a valid email address"
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Get OTP
              </Button>
            </Form.Item>
          </Form>
        </Card>
      ) : (
        <Card title="Get OTP" style={{ width: 400 }}>
          <p>Enter OTP sent to {email}</p>
          <OtpInput length={6} onOtpSubmit={onOtpSubmit} />
        </Card>
      )}
    </div>
  );
};

export default ForgetPassword;
