import React from "react";
import { Tabs, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import AdminQuiz from "./AdminQuiz";
import UpdateQuiz from "./UpdateQuiz";


const { TabPane } = Tabs;

const QuizManagement = () => {
  return (
    <>
      <div style={{ padding: "20px" }}>
      <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "5px",
      }}
    >
      <p style={{ fontSize: "22px" }}>Quiz</p>
      <Breadcrumb style={{ margin: "22px 0" }}>
        <Breadcrumb.Item>Quiz</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/admin/dashboard">
            <HomeOutlined />
          </a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
        <Tabs defaultActiveKey="1" tabPosition="top">
          <TabPane tab="Quiz" key="1">
        <AdminQuiz />
          </TabPane>
          <TabPane tab="Update Request" key="2">
          <UpdateQuiz />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default QuizManagement;
