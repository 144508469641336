import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  otp: [],
  state: "idle", // idle, loading, success, error
  success: null,
  error: null,
};

export const forgetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_OTP:
      return {
        ...state,
        otp: action.payload.otp,
      };
      case actionTypes.VERIFY_OTP:
        return {
          ...state,
          state: "success",
          success: action.payload.success,
        };
        case actionTypes.GENERATE_PASSWORD:
          return {
            ...state,
            state: "success",
            success: action.payload.success,
          };

    default:
      return state;
  }
};

export default forgetPasswordReducer;
