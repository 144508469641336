import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  Card,
  message,
  Row,
  Col,
  Spin,
} from "antd";
import { HomeOutlined } from "@ant-design/icons";
import {
  getCategory,
  updateCategory,
} from "../../../actions/addCategory/addCategory";
import { useDispatch } from "react-redux";

const EditCategory = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setGetLoading(true);
        const result = await dispatch(getCategory());
        const foundCategory = result?.data?.find(
          (category) => category.id === id
        );
        if (foundCategory) {
          form.setFieldsValue({
            categoryName: foundCategory.categoryName,
          });
        } else {
          console.error("Category not found.");
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setGetLoading(false);
      }
    };

    fetchData();
  }, [dispatch,form, id]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("categoryName", values.categoryName);
      formData.append("categoryImage", image);
      formData.append("id", id);
      const response = await dispatch(updateCategory(formData));

      if (response.success) {
        message.success(response.message);
        form.resetFields();
        setImage(null);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const fileName = event.target.files[0];
      setImage(fileName);
    }
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <p style={{ fontSize: "22px" }}>Edit Category</p>
          <Breadcrumb style={{ margin: "22px 0" }}>
            <Breadcrumb.Item>Edit Category</Breadcrumb.Item>
            <Breadcrumb.Item>
              {" "}
              <a href="/admin/category/view"> Category </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/admin/dashboard">
                <HomeOutlined />
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {getLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <Spin size="medium" />
          </div>
        ) : (
          <Card style={{ padding: "24px" }}>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    label="Category Name"
                    name="categoryName"
                    rules={[
                      { required: true, message: "Please enter category name" },
                    ]}
                  >
                    <Input placeholder="Category Name" />
                  </Form.Item>
                </Col>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    label="Upload Image"
                    name="categoryImage"
                    rules={[{ required: true, message: "Please select image" }]}
                  >
                    <Input
                      type="file"
                      onChange={onImageChange}
                      className="filetype"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item style={{ textAlign: "center" }}>
                {/* <Button type="default" style={{ marginRight: 8 }}>
                Cancel
              </Button> */}
                <Button
                  htmlType="button"
                  onClick={() => form.resetFields()}
                  style={{ marginRight: 8 }}
                >
                  Reset
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </div>
    </>
  );
};

export default EditCategory;
