import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  Card,
  Select,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { getSubCategory } from "../../../actions/addSubCategory/addSubCategory";
import { getCategory } from "../../../actions/addCategory/addCategory";
import { useDispatch } from "react-redux";
import { getAasana, updateAasana } from "../../../actions/addAasana/addAasana";

const { Option } = Select;
const { TextArea } = Input;

const EditAasana = (props) => {
  const { id ,pageSize,currentPage} = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const data = {
        id,
        ...values,
      };
      // console.log(data)
      setAddLoading(true);
      const response = await dispatch(updateAasana(data));

      if (response.success) {
        message.success(response.message);
        form.resetFields();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error.response.data.message);
    } finally {
      setAddLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // const params={
        //   limit:pageSize,
        //   page:currentPage
        // }
        const result = await dispatch(getCategory());
        setCategory(result.data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch,pageSize,currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // const params={
        //   limit:pageSize,
        //   page:currentPage
        // }
        const result = await dispatch(getSubCategory());
        setData(result.data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch,pageSize,currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const params={
          limit:pageSize,
          page:currentPage
        }
        const result = await dispatch(getAasana(params));
        const foundCategory = result?.data?.find(
          (category) => category.id === id
        );
        if (foundCategory) {
          form.setFieldsValue({
            categoryId: foundCategory.categoryId,
            subCategoryId:foundCategory.subCategoryId,
            aasanaName:foundCategory.aasanaName,
            aasanaDescription:foundCategory.aasanaDescription,
            aasanaTag:foundCategory.aasanaTag,
            videoPath:foundCategory.videoPath,
            videoDuration:foundCategory.videoDuration,
            benefits:foundCategory.benefits,
            instructions:foundCategory.instructions

          });
        } else {
          console.error("Category not found.");
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id,pageSize,currentPage,form]);

  console.log(data)

  return (
    <>
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <p style={{ fontSize: "22px" }}>Edit Aasanas</p>
          <Breadcrumb style={{ margin: "22px 0" }}>
            <Breadcrumb.Item>Edit Aasanas</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/admin/aasana/view">
                Aasana
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/admin/dashboard">
                <HomeOutlined />
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Card style={{ padding: "24px" }}>
          <Spin spinning={loading} tip="Loading...">
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    label="Category Name"
                    name="categoryId"
                    rules={[
                      {
                        required: true,
                        message: "Please select category name!",
                      },
                    ]}
                  >
                    <Select placeholder="Select Category">
                      {category?.map((category) => (
                        <Option key={category.id} value={category.id}>
                          {category.categoryName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="subCategoryId"
                    label="Sub Category Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter sub category name",
                      },
                    ]}
                  >
                    <Select placeholder="Select Sub-category">
                      {data?.map((category) => (
                        <Option key={category.id} value={category.id}>
                          {category.subCategoryName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="aasanaName"
                    label="Aasana Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter aasana name!",
                      },
                    ]}
                  >
                    <Input placeholder="Aasana Name" />
                  </Form.Item>
                </Col>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="aasanaDescription"
                    label="Aasana Description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter aasana description!",
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Enter aasana description" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="aasanaTag"
                    label="Tag"
                    rules={[{ required: true, message: "Please enter tag" }]}
                  >
                    <Input placeholder="Aasana Tag" />
                  </Form.Item>
                </Col>

                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="videoPath"
                    label="Video Path"
                    rules={[
                      { required: true, message: "Please enter video path" },
                    ]}
                  >
                    <Input placeholder="Video Path" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="videoDuration"
                    label="Video Duration"
                    rules={[
                      {
                        required: true,
                        message: "Please enter video duration",
                      },
                    ]}
                  >
                    <Input placeholder="Video Duration" />
                  </Form.Item>
                </Col>

                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="benefits"
                    label="Benefits"
                    rules={[
                      {
                        required: true,
                        message: "Please enter aasana benefits!",
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder="Enter Benefits" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="instructions"
                    label="Instructions"
                    rules={[
                      {
                        required: true,
                        message: "Please enter aasana instructions!",
                      },
                    ]}
                  >
                    <TextArea rows={3} placeholder="Enter Intructions" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  htmlType="button"
                  onClick={() => form.resetFields()}
                  style={{ marginRight: 8 }}
                >
                  Reset
                </Button>
                <Button type="primary" htmlType="submit" loading={addLoading}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </>
  );
};

export default EditAasana;
