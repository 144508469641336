import * as api from "../../api";
import { GET_ADMIN_QUIZ,APPROVE_QUIZ,DECLINE_QUIZ,GET_UPDATION_QUIZ,APPROVE_QUIZ_UPDATION,DECLINE_QUIZ_UPDATION} from "../../constants/actionTypes";

export const getAdminQuiz = (params) => async (dispatch) => {
  try {
    const { data } = await api.getAdminQuiz(params);
    dispatch({ type: GET_ADMIN_QUIZ, payload: data });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const approveQuiz = (id) => async (dispatch) => {
    try {
      const { data } = await api.approveQuiz(id);
      dispatch({ type: APPROVE_QUIZ, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  export const declineQuiz = (id) => async (dispatch) => {
    try {
      const { data } = await api.declineQuiz(id);
      dispatch({ type: DECLINE_QUIZ, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  export const getUpdationQuiz = (params) => async (dispatch) => {
    try {
      const { data } = await api.getUpdationQuiz(params);
      dispatch({ type: GET_UPDATION_QUIZ, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  export const approveQuizUpdation = (id) => async (dispatch) => {
    try {
      const { data } = await api.approveQuizUpdation(id);
      dispatch({ type: APPROVE_QUIZ_UPDATION, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  export const declineQuizUpdation = (id) => async (dispatch) => {
    try {
      const { data } = await api.declineQuizUpdation(id);
      dispatch({ type: DECLINE_QUIZ_UPDATION, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };